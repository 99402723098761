<template lang="pug">
div.container
  div(v-if="!groups")
    loading-spinner(message="Loading MailChimp mailing lists...")
  div(v-if="groups")
    b-row(align-h="center").mb-4
      h1 Create New Visitor

    div.row
      div.col-md-4.d-none.d-md-block
        b-list-group.mb-2
          b-list-group-item(active) Visitor Details
          b-list-group-item.d-flex.justify-content-between.align-items-center First name <span>{{newVisitor.firstName}}</span>
          b-list-group-item.d-flex.justify-content-between.align-items-center Last name <span>{{newVisitor.lastName}}</span>
          b-list-group-item.d-flex.justify-content-between.align-items-center Email <span>{{newVisitor.email}}</span>
          b-list-group-item.d-flex.justify-content-between.align-items-center Organization <span>{{newVisitor.organization}}</span>
      div.col-md-8
        b-form
          b-form-row
            b-form-group.col-sm(label="First Name *" label-for="firstName")
              b-input(type="text" name="firstName" v-model="newVisitor.firstName" required)
            b-form-group.col-sm(label="Last Name *" label-for="lastName")
              b-input(type="text" name="lastName" v-model="newVisitor.lastName" required)
          b-form-row
            b-form-group.col-sm(label="Email *" label-for="email")
              b-input(type="email" name="email" v-model="newVisitor.email" required)
            b-form-group.col-sm(label="Organization" label-for="organization")
              b-input(type="text" name="organization" v-model="newVisitor.organization")
          b-form-row
            b-form-group.col-sm(label="MailChimp Mailing Lists")
              b-form-checkbox(v-for="group in groups" :key="group.id" v-model="newVisitor.interests[group.id]") {{group.name}}
          b-form-row.justify-content-center.m-4
            b-button(variant="success" @click="createVisitor(newVisitor)" :disabled="!isFormValid") Create Visitor

    dev-data-dump(:data="newVisitor")
</template>

<script>
export default {
  data: function () {
    return {
      groups: null,
      newVisitor: {
        firstName: '',
        lastName: '',
        email: '',
        organization: '',
        interests: {}
      }
    }
  },
  computed: {
    isFormValid: function () {
      let result = true
      if (this.newVisitor.firstName === '' || this.newVisitor.lastName === '' || this.newVisitor.email === '') { result = false }
      return result
    }
  },
  mounted: function () {
    this.axios.get('/api/visitors/mailchimp/groups')
      .then(res => (this.groups = res.data))
      .catch(err => {
        this.$notify({
          group: 'error',
          type: 'error',
          title: 'Fetching MailChimp groups failed',
          text: err.response.data,
          duration: -1
        })
      })
  },
  methods: {
    createVisitor: function (newVisitor) {
      this.axios.post('/api/visitors/new', newVisitor)
        .then(res => {
          this.$notify({
            group: 'success',
            type: 'success',
            title: 'Visitor created',
            text: 'Tell ' + res.data.firstName + ' to join an event!'
          })

          this.$router.push({ path: '/events/visitors' })
        })
        .catch(err => {
          this.$notify({
            group: 'error',
            type: 'error',
            title: 'Creating visitor failed',
            text: err.response.data,
            duration: -1
          })
        })
    }
  }
}
</script>
